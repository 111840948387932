import { Link, useNavigate } from "react-router-dom";
import useCustomLogin from "../../hooks/useCustomLogin";
import { useModal } from "../common/ModalContext";
import { getCookie } from "../../util/cookieUtil";
import React, { useEffect, useState } from "react";
import { getPeriodKingdom } from "../../api/gameApi";
import { toast } from "react-toastify";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { postSetGameAsync } from "../../redux/slices/gameSetSlice";
import { setGameControl } from "../../redux/slices/gameControlSlice";
import { Helmet } from "react-helmet";
import { getLangCode } from "../../util/codeUtil";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

const IndexComponent = ({ pridx }) => {
  const { t, i18n } = useTranslation();

  //console.log("^^IndexComponent : ", pridx);
  const [prName, setPrName] = useState("");
  const [prDesc, setPrDesc] = useState("");
  const [kds, setKds] = useState([]);
  const [lang, setLang] = useState(getCookie("lang") || "en");
  const [supportLangs, setSupportLangs] = useState("ko");

  const [isAutoRun, setIsAutoRun] = useState(false);
  const [heart, setHeart] = useState(10);
  const [isShowHeart, setIsShowHeart] = useState(false);

  const [gameSpeed, setGameSpeed] = useState("150");

  const { loginState, moveToLogin } = useCustomLogin();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { openModal } = useModal();

  const memberId = loginState.memberId;

  const gameSetState = useSelector((state) => state.gameSetSlice, shallowEqual);

  const handleClickGame = async (e) => {
    e.preventDefault();
    // 로그인 체크
    if (!getCookie("member")) {
      modalLogin();
      return;
    }

    const checkLang = getLangCode(lang);
    console.log("checkLang : ", checkLang, ",", supportLangs);

    const isInclude = supportLangs.split(",").includes(lang);
    const ckResult = supportLangs.split(",").map(getLangCode).join(", ");

    if (!isInclude) {
      toast.info(
        prName +
          t("common-msg-lang-particle1") +
          checkLang +
          t("common-msg-lang-support1") +
          ". \n ℹ️" +
          t("common-msg-lang-support2") +
          " : " +
          ckResult +
          "",
        { theme: "dark", style: { fontSize: "11px" } }
      );
      return false;
    }

    i18n.changeLanguage(lang);

    // 선택된 입력값들을 params로 전달
    const params = {
      memberId,
      pridx,
      lang, // 선택된 언어
      isAutoRun,
      heart,
      gameSpeed,
    };

    console.log("^^params:", params);

    try {
      await dispatch(postSetGameAsync(params));
      navigate("/game/start/" + pridx);
    } catch (err) {
      toast.error(err.message, { theme: "dark" });
    }
  };

  const modalLogin = () => {
    console.log("-- 로그인 모달 --");
    openModal({
      type: "SIMPLE",
      content: t("common-plz-login"),
      onClose: () => moveToLogin(),
    });
  };

  const handleLanguageChange = (e) => {
    setLang(e.target.value); // 선택된 라디오 버튼의 값
  };

  const handleAutoRunChange = (e) => {
    setIsAutoRun(e.target.checked);
  };

  const handleHeartChange = (e) => {
    setHeart(e.target.value);
  };

  const handleGameSpeedChange = (e) => {
    setGameSpeed(e.target.value);
  };

  useEffect(() => {
    console.log("^^gameSetState:", gameSetState);
    console.log("^^getCookie(lang):", getCookie("lang"));

    getPeriodKingdom({ pridx: pridx, memberId: memberId })
      .then((response) => {
        // console.log(
        //   "^^index-getPeriodKingdomDesc-response-data : ",
        //   response.data
        // );
        setPrName(response.data.name);
        setPrDesc(response.data.description);
        setKds(response.data.kingdoms);
        setSupportLangs(response.data.supportLangs);
      })
      .catch((err) => {
        console.log("^^index-getPeriodKingdomDesc-error : ", err);
        toast.error(err.errorMessage, { theme: "dark" });
        navigate({ pathname: "/main/game-list" }, { replace: true });
      });
  }, [pridx, memberId]);

  return (
    <>
      <Helmet>
        <title>{t("html-header-gameindex-title")}</title>
        <meta
          name="description"
          content={t("html-header-gameindex-meta-description")}
        />
        <meta
          property="og:title"
          content={t("html-header-gameindex-meta-title")}
        />
        <meta
          property="og:description"
          content={t("html-header-gameindex-meta-description")}
        />
      </Helmet>

      <div className="set-info roll">
        <div className="game-setting inner">
          <h2 className="name" onClick={() => setIsShowHeart(!isShowHeart)}>
            {prName}
          </h2>
          <div className="about">
            <p>
              {kds.map((kd, index) => (
                <React.Fragment key={index}>· {kd.name} &nbsp;</React.Fragment>
              ))}
            </p>
            <span>{prDesc}</span>
            {/* <p>· 대한민국</p>
            <span>
              대한민국은 1948년에 건국된 이후, 비교적 짧은 기간 동안 놀라운
              경제적, 정치적 발전을 이루어낸 나라로 평가받습니다.
            </span> */}
          </div>
          <div className="setting-area">
            <div className="lang">
              <h3>{t("game-index-lang")}</h3>
              <input
                type="radio"
                name="lang"
                id="korea"
                value={"ko"}
                checked={lang === "ko"}
                onChange={handleLanguageChange}
              />
              <input
                type="radio"
                name="lang"
                id="english"
                value={"en"}
                checked={lang === "en"}
                onChange={handleLanguageChange}
              />
              <input
                type="radio"
                name="lang"
                id="japanese"
                value={"ja"}
                checked={lang === "ja"}
                onChange={handleLanguageChange}
              />
              <input
                type="radio"
                name="lang"
                id="chinese"
                value={"zh"}
                checked={lang === "zh"}
                onChange={handleLanguageChange}
              />
              <label htmlFor="korea" className="kor">
                <em></em>
                <p>KOR</p>
              </label>
              <label htmlFor="english" className="eng">
                <em></em>
                <p>ENG</p>
              </label>
              <label htmlFor="japanese" className="jpn">
                <em></em>
                <p>JPN</p>
              </label>
              <label htmlFor="chinese" className="chn">
                <em></em>
                <p>CHN</p>
              </label>
            </div>

            <div className="speed-auto-btn">
              <div className="speed-btn">
                <h3>Speed</h3>
                <input
                  type="radio"
                  name="speed-value"
                  id="speed-value1"
                  value="200"
                  onChange={handleGameSpeedChange}
                  checked={gameSpeed === "200"}
                />
                <input
                  type="radio"
                  name="speed-value"
                  id="speed-value2"
                  value="150"
                  onChange={handleGameSpeedChange}
                  checked={gameSpeed === "150"}
                />
                <input
                  type="radio"
                  name="speed-value"
                  id="speed-value3"
                  value="100"
                  onChange={handleGameSpeedChange}
                  checked={gameSpeed === "100"}
                />
                <label htmlFor="speed-value1" className="value1">
                  <em>1</em>
                </label>
                <label htmlFor="speed-value2" className="value2">
                  <em>2</em>
                </label>
                <label htmlFor="speed-value3" className="value3">
                  <em>3</em>
                </label>
              </div>
              <div className="auto-btn">
                <input
                  type="checkbox"
                  name="autorun"
                  value="true"
                  id="auto-onoff"
                  checked={isAutoRun}
                  onChange={handleAutoRunChange}
                />
                <label htmlFor="auto-onoff">
                  <h3>{t("game-index-auto")}</h3>
                  <em data-txt="ON"></em>
                </label>
              </div>
            </div>

            {/* <div className="vox">
            <input type="checkbox" name="" id="s-onoff" checked />
            <label for="s-onoff">
              <div className="sound">
                <h3>사운드</h3>
                <div className="icon-box"></div>
              </div>
            </label>

            <input type="checkbox" name="" id="v-onoff" />
            <label for="v-onoff">
              <div className="voice">
                <h3>음성지원</h3>
                <div className="icon-box"></div>
              </div>
            </label>
          </div> */}

            {isShowHeart && (
              <div>
                <input
                  type="text"
                  name="heart"
                  id="heart"
                  value={heart}
                  onChange={handleHeartChange}
                />
                {/* <input
                type="text"
                name="gameSpeed"
                id="gameSpeed"
                value={gameSpeed}
                onChange={handleGameSpeedChange}
              /> */}
              </div>
            )}
          </div>
          <button className="start-btn" onClick={handleClickGame}>
            {t("game-index-start")}
          </button>
        </div>
      </div>
    </>
  );
};

export default IndexComponent;
