import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { getCookie, setCookie } from "./util/cookieUtil";
import axios from "axios";
import { API_SERVER_HOST } from "../src/api/apiConfig";

const savedLang = getCookie("lang");

const browserLang = navigator.language.split("-")[0];

console.log("^^i18n-browserLang : ", browserLang);

const defaultLang =
  savedLang ||
  (["ko", "en", "es", "ja"].includes(browserLang) ? browserLang : "en");

if (!savedLang) {
  setCookie("lang", defaultLang, 365);

  // api server(lang)
  const host = `${API_SERVER_HOST}/api`;
  await axios.post(
    `${host}/lang/changeLang`,
    { lang: defaultLang },
    {
      withCredentials: true,
    }
  );
}

console.log("^^i18n : ", savedLang, browserLang, defaultLang);

i18n
  .use(HttpApi) // JSON 파일에서 번역 데이터 불러오기
  .use(LanguageDetector) // 사용자의 브라우저 언어 감지
  .use(initReactI18next) // React에서 i18n을 사용할 수 있도록 초기화
  .init({
    lng: defaultLang, // 초기 언어 설정
    fallbackLng: "en", //  지원하지 않는 언어일 경우 (en)
    debug: true,
    interpolation: {
      escapeValue: false, // React에서 JSX를 사용할 때 필요
    },
    resources: {
      ko: { translation: require("./locales/ko.json") },
      en: { translation: require("./locales/en.json") },
    },
  });

export default i18n;
